import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import Header from "../../components/header/Header"
import { graphql } from "gatsby"
import MainWrap from "../../components/main-wrap/MainWrap"
import MainTitle from "../../components/main-title/MainTitle"
import { withTranslation, WithTranslation } from "react-i18next"

import {
  Link,
  DirectLink,
  Element,
  Events,
} from "react-scroll"
import {
  SiteSubtitle,
  MenuWrap,
  MenuHolder,
  HolderWrapMenu,
} from "./Drinks.css"
import MenuItem from "../../components/menu-item/MenuItem"
import { Title, Subtitle } from "../../components/menu-item/MenuItem.css"
import AlertIcon from "../../assets/images/alert.png"
import { Lang, PageContext } from "../../constants"
export type Props = {
  data?: any
  pageContext: PageContext
}
export type Functions = {}
export type AllProps = Props & Functions & WithTranslation
const Menu: FunctionComponent<AllProps> = ({ data, pageContext, t }) => {
  const nodes = data.allMarkdownRemark.nodes
  const [activeMenu, setActiveMenu] = useState(0)
  const [colspan, setColspan] = useState(false)

  const getValue = (param, object = null) => {
    const query = object || data.allMarkdownRemark.nodes[0].frontmatter
    try {
      return pageContext.lang === Lang.LV ? query[param] : query[`${param}_en`] || ""
    } catch (e) {
      console.warn(e)
      return ""
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setColspan(window.innerWidth < 750)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const isElementInViewport = el => {
    try {
      var rect = el.getBoundingClientRect()
      const isInViewPort =
        rect.bottom > 150 &&
        rect.top + 100 <
          (window.innerHeight || document.documentElement.clientHeight)
      return isInViewPort
    } catch (e) {
      return false
    }
  }

  return (
    <MainWrap>
      <Header
        lang={pageContext.lang}
        title={"Delisnack dzērienu piegāde"}
      />
      <HolderWrapMenu>
        {nodes.map(({ frontmatter }, index) => {
          return (
            <MenuWrap key={getValue("title", frontmatter)} id={`menu-${index}`}>
              <Element
                name={`menu-${index}`}
                key={getValue("title", frontmatter)}
              >
                <MainTitle responsiveTopNudge={index === 0 ? 100 : 60}>
                  {getValue("title", frontmatter)}
                </MainTitle>
                <SiteSubtitle>
                  {getValue("subtitle", frontmatter)}
                </SiteSubtitle>

                {frontmatter.menu && frontmatter.menu.length && (
                  <MenuHolder>
                    {frontmatter?.menu.map((menuItem, index) => {
                      return (
                        <MenuItem
                          colspan={colspan}
                          lang={pageContext.lang}
                          key={index}
                          item={menuItem}
                        />
                      )
                    })}
                  </MenuHolder>
                )}
              </Element>
            </MenuWrap>
          )
        })}
      </HolderWrapMenu>
    </MainWrap>
  )
}

//<Element name={`menu-${index}`} key={frontmatter.title}>

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { fields: { collection: { eq: "drinkGroup" } } }
      sort: { fields: frontmatter___orderId, order: ASC }
    ) {
      nodes {
        frontmatter {
          orderId
          title
          title_en
          subtitle
          subtitle_en
          menu {
            title
            title_en
            subtitle
            subtitle_en
            price
            image
            extras {
              price
              title
              title_en
            }
            sizes {
              price
              title
              title_en
            }
            badge {
              type
            }
          }
        }
      }
    }
  }
`
export default withTranslation()(Menu)
