import styled from "styled-components"

export const MenuWrap = styled.div`
  margin: 0 auto;
  max-width: 100%;
  padding: 0;
  @media (max-width: 960px) {
    padding: 0;
  }
`

export const SiteSubtitle = styled.h2`
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  width:800px;
  max-width:100%;
  margin:-20px auto 30px;
`

export const HolderWrapMenu = styled.div`
  width: 1200px;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 300px 0 300px;

  @media (max-width: 960px) {
    padding: 0 10px;
  }
`
export const MenuHolder = styled.div`
  //display: grid;
  //grid-template-columns: repeat(2, 1fr);
  //grid-template-rows: auto;
  //position: relative;
  //grid-column-gap: 40px;
  //grid-row-gap: 40px;
  //@media (max-width: 900px) {
  //  flex-direction: column;
  //  grid-template-columns: repeat(1, 1fr);
  //}
`
export const SideNav = styled.ul`
  position: absolute;
  top: 211px;
  left: 23px;
  width: 260px;
  height: calc(100vh - 213px);
  overflow: auto;
  @media (max-width: 960px) {
    display: none;
  }
`

export const NavItem = styled.li<{ active: boolean }>`
  font-family: Font-Cond-Semibold;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 5px;
  cursor: pointer;
  background: ${({ theme, active }) =>
    active ? theme.colors.grey2 : theme.colors.mainBg};
  padding: 5px 25px;
  cursor: pointer;
`
export const Alergies = styled.div`
  margin: 0 auto;
  width: 700px;
  max-width: 100%;
  border-top: 1px dashed white;
  margin-top: 40px;
  padding-top: 20px;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  font-family: Font-Semibold;
`
export const FreeSouce = styled.ul`
  display: flex;
  @media (max-width: 960px) {
    display: block;
  }
`

export const LeftSouce = styled.li`
  display: flex;
  border: 2px solid ${({ theme }) => theme.colors.orange};
  flex-shrink: 0;
  p {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 0 20px;
    text-align: center;
  }
  span {
    font-family: Font-Black;
    line-height: 20px;
    font-size: 16px;
    @media (max-width: 960px) {
      width: 100%;
    }

    b {
      font-family: Font-Black;
      color: ${({ theme }) => theme.colors.orange};
    }
  }
`
export const RightSouce = styled.li`
  font-family: Font-Semibold;
  font-size: 14px;
  line-height: 18px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  @media (max-width: 960px) {
    margin-left: 0px;
    margin-top: 20px;
    text-align: center;
  }
`

export const Toppings = styled.div``
